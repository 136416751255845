import React, { useContext } from 'react'
import { ButtonContent } from "../global/boton/ButtonContent";
import { FaMap, FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { GlobalDataContext } from '../../context/context';


function HeroSectionV2({ slogans, urlVideo, text }) {
    const { rpdata } = useContext(GlobalDataContext)





    return (
        <section className="w-full lg:h-[95vh] md:h-[90%] h-[85vh] flex lg:flex-row flex-col pt-5 bg-2">
            <div className="w-[8%] h-full -mx-5 lg:block hidden ">
                <div className="flex flex-col w-full h-full py-10 ">
                    <div className="w-full h-1/3 flex items-center ">
                        <div className="-rotate-90 w-full h-1/2 flex flex-col justify-center items-center space-x-2">
                            <h1 className="text-white font-semibold text-base">Works Days</h1>
                            <div className="flex w-[150px]">
                                <FaRegCalendarAlt className='text-[18px] dotsColor' />
                                <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.workdays?.[0].day}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-1/3 flex items-center">
                        <div className="-rotate-90 w-full h-1/2 flex flex-col justify-center items-center space-x-2">
                            <h1 className="text-white font-semibold text-base">Work Hours</h1>
                            <div className="flex w-[150px]">
                                <FaRegClock className='text-[18px] dotsColor' />
                                <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.workHours?.[0].hour}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-1/3 flex items-center">
                        <div className="-rotate-90 w-full h-1/2 flex flex-col justify-center items-center space-x-2">
                            <h1 className="text-white font-semibold text-base">Location</h1>
                            <div className="flex w-[120px]">
                                <FaMap className='text-[18px] dotsColor' />
                                <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.location?.[0]?.address}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-[100%] lg:w-[95%] md:h-[90vh] h-auto flex flex-col justify-center items-center relative lg:mr-5 mr-0'>
                <div
                    className='md:w-[100%] w-full md:h-full h-[70vh] overflow-y-hidden relative before:z-10 before:absolute before:w-full before:h-full lg:rounded-tr-[40px] rounded-tr-[0px] rounded-bl-[40px] before:bg-[#0000004b]'
                >
                    <video

                        playsInline
                        autoPlay
                        muted
                        loop
                        className="w-full h-full object-cover"
                    >
                        <source
                            src={urlVideo}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className='h-auto lg:w-[50%] w-[90%] flex flex-col gap-4 z-10 text-white absolute top-1/2 -translate-y-1/2 left-[48%] lg:right-[10%] -translate-x-1/2 lg:-translate-x-0 '>
                    <h1 className='text-white lg:text-[58px] md:text-[30px] text-[38px] font-bold text-right'>{slogans}</h1>
                    <span className='lg:text-[18px] md:text-[14px] text-[10px] hidden md:block text-right text-white'>{text}</span>
                    <div className='w-full h-auto flex justify-end'>
                        <ButtonContent direction />
                    </div>
                </div>
            </div>
            <div className='lg:hidden block py-5'>
                <div className="w-full flex items-center justify-center space-x-10">
                    <div className="w-auto flex justify-center items-center space-x-2">
                        <FaRegCalendarAlt className='text-[18px] dotsColor' />
                        <div className="flex flex-col">
                            <span className="text-white uppercase font-semibold text-base">{rpdata?.dbPrincipal?.workdays?.[0].day}</span>
                            <span className="text-white uppercase font-normal text-xs">{rpdata?.dbPrincipal?.workHours?.[0].hour}</span>
                        </div>
                    </div>

                    <div className="w-auto flex justify-center items-center space-x-2">
                        <MdLocationPin className='text-[18px] dotsColor' />
                        <div className="flex flex-col">
                            <span className="text-white uppercase font-semibold text-base">Location</span>
                            <span className="text-white uppercase font-normal text-xs">hh</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default HeroSectionV2